import {IEntityAction, ActionMeta} from 'app/blocks/store/actions/entity-actions';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {IMposfile} from 'app/blocks/model/mpos-file.model';
import {IPurchaseOrder} from 'app/blocks/model/purchase-order.model';

export interface ICreatePurchaseOrder extends IEntityAction {
    entity?: IPurchaseOrder;
    uploadDocuments?: IMposfile[];
    deletedDocuments?: IMposfile[];
}

export interface IUpdatePurchaseOrder extends IEntityAction {
    entity?: IPurchaseOrder;
    uploadDocuments?: IMposfile[];
    deletedDocuments?: IMposfile[];
}

export class CreatePurchaseOrder implements ICreatePurchaseOrder {
    static readonly type = '[Purchase Order] Create Purchase Order';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: IPurchaseOrder,
        public uploadDocuments?: IMposfile[],
        meta?: ActionMeta
    ) {}
}

export class UpdatePurchaseOrder implements IUpdatePurchaseOrder {
    static readonly type = '[Purchase Order] Update Purchase Order';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: IPurchaseOrder,
        public uploadDocuments?: IMposfile[],
        public deletedDocuments?: IMposfile[],
        meta?: ActionMeta
    ) {}
}
